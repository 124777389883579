.Calculator {
  // background: url('../../../assets/backgroundImgs/calculator_bg.png');
  // background: radial-gradient(ellipse at 50% 50%, rgba(255, 255, 255, 1) 0%, rgba(199, 196, 196, 1) 32%, rgba(255, 255, 255, 1) 74%);
  // background: radial-gradient(ellipse at 50% 50%, rgba(255, 255, 255, 1) 0%, rgba(171, 195, 255, 1) 32%, rgba(212, 255, 190, 1) 74%);
  // background: linear-gradient(90deg, rgba(0, 0, 0, 0.87) 0%, rgba(212, 29, 0, 0.6) 50%, rgba(130, 142, 238, 0.87) 100%);
  // background: linear-gradient(199deg, rgba(133, 133, 133, 0.44) 0%, rgba(50, 67, 255, 0.37) 50%, rgba(255, 132, 132, 0.87) 100%);

  background-size: cover;
  @include defaultPage {
    &-Header {
      flex-direction: row;
      justify-content: start;
      &-Info {
        width: 100%;
        display: flex;
        align-items: center;
        // background: red;
        .anticon-info-circle {
          color: $blue;
          margin: 5px 0px;
          padding: 5px;
          font-size: 18px;
          // background: linear-gradient(
          //   #f8f8f800,
          //   #f8f8f8,
          //   #f8f8f8,
          //   #f8f8f8,
          //   #f8f8f8,
          //   #f8f8f8,
          //   #f8f8f8,
          //   #f8f8f800
          // );
        }
      }
      .Breadcrumb {
        min-width: fit-content;
        margin-right: 0px;
        // background: linear-gradient(
        //   #f8f8f800,
        //   #f8f8f8,
        //   #f8f8f8,
        //   #f8f8f8,
        //   #f8f8f8,
        //   #f8f8f8,
        //   #f8f8f8,
        //   #f8f8f800
        // );
      }
    }
    &-Body {
      width: 100%;
      height: 100%;
      padding: 0px;
      justify-content: center;
      align-items: flex-start;
      &_loader {
        align-items: center;
      }
      @include xs {
        align-items: center;
      }
      &-Card {
        width: 100%;
        height: 100%;
        margin: 0px 10px;
        padding: 10px;
        max-width: 1100px;
        min-width: 330px;
        &-Header {
          width: 100%;
          min-height: 161px;
          padding: 10px;
          display: flex;
          flex-direction: column;
          align-items: center;
          background: white;
          @include xs {
            border: 1px solid black;
            border-radius: 5px;
          }
          &-Types {
            // width: calc(100% - 10px);
            width: 100%;
            min-height: 50px;
            display: flex;
            flex-wrap: wrap;
            justify-content: space-around;
            align-items: center;
            border-bottom: 1px solid $silver;
            // @include xs {
            //   width: 80%;
            // }
            &-Button {
              width: initial;
              flex: 1 1;
              margin: 3px;
              padding: 2px 10px;
              overflow: hidden;
              font-size: 10px;
              @include xs {
                min-width: 120px;
                max-width: 220px;
                font-size: 14px;
                padding: 4px 15px;
              }
              &_active {
                color: white;
                background: $blue;
                &:hover {
                  color: white !important;
                  background: $blue !important;
                }
                &:active {
                  color: white !important;
                  background: $blue !important;
                }
              }
            }
          }
          &-Inputs {
            display: flex;
            flex-direction: row;
            flex-wrap: wrap;
          }
          &-Calc {
            width: 100%;
            height: auto;
            display: flex;
            flex-wrap: wrap;
            justify-content: center;
            // background: red;
            @include m {
              width: auto;
            }
            &-Item {
              flex: 1;
              padding: 4px;
              display: flex;
              flex-direction: column;
              justify-content: flex-end;
              * {
                font-size: 12px;
              }
              h5 {
                margin: 8px 0px;
              }
              @include xs {
                * {
                  font-size: 14px;
                }
              }
              @include m {
                flex: none;
                h5 {
                  margin: 15px 0px;
                }
              }
              &-Select {
                width: auto;
                min-width: 80px;
                &-Small {
                  width: auto;
                  font-size: 12px;
                  @include xs {
                    font-size: 14px;
                  }
                  @include m {
                    width: 60px;
                  }
                }
                @include m {
                  width: 95px;
                }
              }
              &-Input {
                // width: 120px;
                font-size: 14px;
                @include m {
                  width: 120px;
                  // min-width: 90px;
                  // max-width: 90px;
                }
                &-Small {
                  width: auto;
                  font-size: 14px;
                  @include m {
                    width: 70px;
                  }
                }
              }
              &-Button {
                width: initial;
                // margin: 3px;
                padding: 2px 10px;
                overflow: hidden;
                font-size: 10px;
                @include xs {
                  font-size: 14px;
                  padding: 4px 15px;
                }
              }
            }
          }
        }
        &-Body {
          width: 100%;
          // height: 498px;
          margin-top: 15px;
          background: white;
          border: 1px solid black;
          border-radius: 5px;
          overflow: hidden;
          .Table {
            height: 100%;
            min-height: 250px;
            border-radius: 5px;
            .ant-table-body {
              height: 100%;
            }
            &_empty {
              .ant-table-tbody {
                height: calc(100dvh - 309px);
                @include xs {
                  height: calc(100dvh - 439px);
                }
              }
            }
          }
        }
      }
    }
  }
}
