.MainLayout {
  width: 100%;
  height: 100dvh;
  display: flex;
  flex-direction: row;
  overflow: hidden;
  &-Body {
    width: 100%;
    min-width: 360px;
    height: 100%;
    padding: 0px;
    margin: 0px;
    display: flex;
    flex-direction: column;
    overflow: hidden;
    // overflow-y: auto;
  }
  &-Content {
    height: calc(100dvh - 60px);
    overflow: hidden;
    @include xs {
      height: calc(100dvh - 70px);
    }
    // display: flex;
    // flex-direction: column;
  }
}
