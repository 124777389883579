.HeaderMain {
  // width: 100%;
  height: 60px;
  padding: 0px 10px;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
  box-shadow: rgba(60, 64, 67, 0.3) 0px 1px 2px 0px,
    rgba(60, 64, 67, 0.15) 0px 2px 6px 2px;
  @include xs {
    height: 70px;
    justify-content: space-between;
  }
  &-MenuIcon {
    display: contents;
    padding: 10px;
    transition: 0.3s;
    &_rotate {
      -webkit-transform: rotate(-180deg);
      transition: 0.3s;
    }
    @include xs {
      display: none;
    }
  }
  &-Time {
    width: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
  }
  &-Avatar {
    background: #ff0000;
  }
  &-Localization {
    width: 60px;
    height: 20px;
    // padding-top: 5px;
    margin: 5px;
    // border: 1px solid $silver;
    // border-radius: 5px;
    .ant-select-selector {
      // width: 80px;
      padding: 0px 3px !important;
    }
    &-Select {
      width: 60px;
      // height: 28px;
      height: 20px;
      margin: 0px;
      display: flex;
      flex-direction: row;
      justify-content: center;
      align-items: center;
      svg {
        height: 16px;
      }
      p {
        margin: 0px;
        margin-left: 5px;
        font-size: 18px;
      }
    }
    &-Item {
      width: 50px;
      height: 30px;
      display: flex;
      flex-direction: row;
      // align-items: center;
      justify-content: center;
      // flex-wrap: nowrap;
      // justify-content: center;
      p {
        margin: 0px;
        margin-left: 5px;
        font-size: 18px;
      }
      // width: 50px !important;
      svg {
        height: 30px;
      }
      .ant-select-item {
        // min-height: 0px;
        padding: 0px;
        // margin-bottom: -5px;
        background-color: white !important;
      }
    }
  }
}
