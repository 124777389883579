.Users {
  overflow: hidden;
  @include defaultPage {
    &-Header {
      // height: 100px;
      flex-direction: column;
      &-Bar {
        display: flex;
        flex-direction: row;
        justify-content: space-between;
        align-items: center;
        .Breadcrumb {
          min-width: 250px;
        }
        &-Settings {
          width: 100%;
          margin: 0px 20px;
          display: flex;
          justify-content: flex-end;
        }
      }
      &-Filters {
        margin: 10px 15px;
        // margin-right: 35px;
        display: flex;
        flex-direction: row;
        justify-content: space-between;
        align-items: center;
        // margin: 5px 0px;
        // flex: 1;
        // display: flex;
        flex-wrap: wrap;
        &-Group {
          margin: 5px 0px;
          margin-bottom: 10px;
          display: flex;
          flex-direction: row;
          flex-wrap: wrap;
          // justify-content: center;
          align-items: center;
        }
        &-Search {
          max-width: 200px;
          // margin-bottom: 8px;
        }
        &-Select {
          width: 90px;
          margin: 0px 10px;
        }
      }
    }
  }
  &-Body {
    // width: auto;
    // height: calc(100dvh - 120px);
    height: calc(100dvh - 200px);
    display: flex;
    justify-content: center;
    overflow: hidden;
    overflow-y: auto;
    @include xs {
      // width: calc(100dvw - 260px);
    }
    // height: calc(100dvh - 300px);
    // display: flex;
    // flex-direction: row;
    // flex-wrap: wrap;
    // overflow-y: hidden;
  }
  &-Modals {
    &-Settings {
      &-Item {
        margin: 5px 0px;
        &-Group {
          display: flex;
          p {
            margin: 0px 10px;
            font-weight: 700;
            font-size: 18px;
            // line-height: 10px;
            align-items: center;
          }
        }
        &-QR {
          width: 300px;
          height: 160px;
          display: flex;
          justify-content: center;
          align-items: center;
          border: 1px solid black;
          border-radius: 5px;
          overflow: hidden;
          img {
            width: auto;
            height: 270px;
          }
        }
        .ant-switch-checked {
          background: $green;
          &:hover {
            background: $green !important;
          }
          &:active {
            background: $green !important;
          }
        }
        .anticon-info-circle {
          color: $blue;
          margin: 5px;
          font-size: 18px;
        }
        .ant-form-item-control-input-content {
          width: 100%;
          display: flex;
          flex-direction: row;
          justify-content: space-between;
          align-items: center;
        }
      }
      &-Button {
        margin: 0px;
        margin-top: 15px;
        .ant-form-item-control-input-content {
          width: 100%;
          display: flex;
          flex-direction: row;
          justify-content: flex-end;
          align-items: center;
        }
      }
    }
  }
}
