.SignIn {
  width: 100%;
  height: auto;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  .Logo {
    width: 200px;
  }
}
