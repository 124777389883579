.Breadcrumb {
  margin: 0px 15px;
  min-width: 300px;
  flex: 0.33;
  display: flex;
  flex-wrap: nowrap;
  color: $brand_color;
  &-Content {
    font-size: x-large;
    font-weight: 700;
  }
  &-Separator {
    font-size: x-large;
  }
  li:last-child {
    color: $blue;
  }
}