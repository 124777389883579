.PickHistory {
  overflow: hidden;
  @include defaultPage {
    &-Header {
      // height: 100px;
      flex-direction: row;
      .Breadcrumb {
        min-width: 150px;
      }
      &-Switch {
        display: flex;
        align-items: center;
        .ant-switch-checked {
          background: $green;
          &:hover {
            background: $green !important;
          }
          &:active {
            background: $green !important;
          }
        }
        .anticon-info-circle {
          color: $blue;
          margin: 5px;
          font-size: 18px;
        }
        @include xs {
          width: 100%;
        }
      }
      &-Filters {
        margin: 0px 15px;
        // margin-right: 35px;
        display: flex;
        flex-direction: row;
        justify-content: center;
        align-items: center;
        // margin: 5px 0px;
        // flex: 1;
        // display: flex;
        flex-wrap: wrap;
        @include xl {
          justify-content: space-between;
        }
        &-Group {
          width: 250px;
          margin: 5px 0px;
          margin-bottom: 10px;
          display: flex;
          flex-direction: column;
          flex-wrap: wrap;
          // background: red;
          justify-content: space-between;
          align-items: center;
          .DateFilters {
            flex: none;
            justify-content: center;
            margin: 0px;
            &-Select {
              width: 240px;
              margin: 0px;
              @include xs {
                width: 120px;
              }
            }
            &-Picker {
              width: 100%;
              @include xs {
                width: 215px;
              }
            }
          }
          @include xs {
            width: fit-content;
            flex-direction: row;
          }
        }
        &-GameFilters {
          width: fit-content;
          // flex: 1;
          min-width: 250px;
          // margin: 0px 5px;
          // padding: 0px 5px;
          display: flex;
          flex-direction: column;
          justify-content: flex-start;
          align-items: center;
          flex-wrap: wrap;
          @include xs {
            flex-direction: row;
            justify-content: center;
          }
        }
        &-Search {
          width: 240px;
          @include xs {
            width: 110px;
          }
          // margin: 0px 5px;
          // margin-bottom: 8px;
        }
        &-Select {
          width: 240px;
          margin: 5px;
          @include xs {
            width: 110px;
          }
          &-Market {
            width: 240px;
            margin: 0px;
            @include xs {
              width: 135px;
            }
          }
        }
        &-Button {
          background: $blue;
          color: white;
          margin: 5px;
          &:hover {
            color: white !important;
            background: $blue !important;
          }
          &:active {
            color: white !important;
            background: $blue !important;
          }
          &-Group {
            width: 100%;
            margin-top: 20px;
            display: flex;
            flex-direction: row;
            justify-content: flex-end;
            align-items: center;
          }
        }
      }
      &-Logo {
        min-width: 210px;
        margin: 0px 5px;
        display: flex;
        justify-content: space-around;
        align-items: center;
        color: $blue;
        font-weight: 500;
        .green {
          color: $green;
        }
      }
      &_jst-btw {
        justify-content: space-between;
      }
    }
  }
  &-Body {
    // width: auto;
    // height: calc(100dvh - 120px);
    height: calc(100dvh - 200px);
    display: flex;
    justify-content: center;
    overflow: hidden;
    overflow-y: auto;
    @include xs {
      // width: calc(100dvw - 260px);
    }
    &-DelIcon {
      font-size: 18px;
      color: red;
    }
    &-Status {
      width: 10px;
      height: 10px;
      border-radius: 5px;
      background: $green;
    }
    &-Result {
      display: flex;
      justify-content: center;
      align-items: center;
      &-L {
        color: #ce0000;
        font-size: 18px;
        font-weight: 700;
        margin: 0px 2px;
      }
      &-R {
        color: #0072c8;
        font-size: 18px;
        font-weight: 700;
        margin: 0px 2px;
      }
      &-Ball {
        width: 30px;
        height: 30px;
        margin: 2px;
        border-radius: 50%;
        display: flex;
        justify-content: center;
        align-items: center;
        color: white;
        box-shadow: inset 0px 4px 4px rgba(0, 0, 0, 0.25);
        font-size: 18px;
        font-weight: 500;
        // &-1 {
        //   background: red;
        // }
        // &-2 {
        //   background: rgb(219, 219, 40);
        // }
        // &-3 {
        //   background: rgb(2, 172, 2);
        // }
        // &-4 {
        //   background: blue;
        // }
        // &-5 {
        //   background: rgb(168, 6, 168);
        // }
        // &-6 {
        //   background: black;
        //   box-shadow: inset 0px 4px 4px rgba(255, 255, 255, 0.64);
        // }
        &-Crown::before {
          content: '';
          background: url('../../../assets/icons/crown.svg');
          background-repeat: no-repeat;
          position: absolute;
          top: 2px;
          width: 12px;
          height: 8px;
        }
        &-1 {
          // background: red;
          background: radial-gradient(
            circle at 10% 0%,
            rgb(255, 66, 66),
            hsla(0, 100%, 36%, 0.959)
          );
        }
        &-2 {
          background: radial-gradient(circle at 30% 30%, #ffff00, #774400);
        }
        &-3 {
          // background: #02ac02;
          background: radial-gradient(circle at 30% 30%, #1eff00, #774400);
        }
        &-4 {
          // background: #0000ff;
          background: radial-gradient(circle at 30% 30%, #0554ff, #412222);
        }
        &-5 {
          // background: rgb(168, 6, 168);
          background: radial-gradient(circle at 30% 30%, #e205ff, #412222);
        }
        &-6 {
          background: black;
          box-shadow: inset 0px 4px 4px rgba(255, 255, 255, 0.64);
        }
        &-7 {
          background: #c4863b;
          box-shadow: inset 0px 4px 4px rgba(255, 255, 255, 0.64);
        }
        &-8 {
          background: #888889;
          box-shadow: inset 0px 4px 4px rgba(255, 255, 255, 0.64);
        }
        //break line
        &-_ {
          border-right: 2px solid black;
          height: 20px;
          margin: 0px 2px;
        }
      }
    }
    &-Accuracy {
      &-Correct {
        color: green;
        font-weight: 500;
      }
      &-Incorrect {
        color: red;
        font-weight: 500;
      }
    }
    // height: calc(100dvh - 300px);
    // display: flex;
    // flex-direction: row;
    // flex-wrap: wrap;
    // overflow-y: hidden;
  }
}
