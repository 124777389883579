.LiveTime {
  display: flex;
  align-items: center;
  // padding: 0px 5px;
  font-size: 15px;
  @include xs {
    font-size: 14px;
  }
  &-Time {
    width: 140px;
  }
  &-Timezone {
    width: 90px;
    margin: 0px 10px;
    // height: 24px;
  }
}
