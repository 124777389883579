.MenuMain {
  width: 100%;
  padding: 0px;
  margin: 0px;
  display: flex;
  flex-direction: column;
  position: absolute;
  bottom: 0px;
  height: calc(100dvh - 60px);
  background: $brand_color;
  z-index: 1000;
  @include xs {
    flex-direction: column;
    position: relative;
    width: auto;
    height: 100vh;
  }
  &_hide {
    display: none;
  }
  &-Section {
    // background-color: aqua;
    display: flex;
    flex-direction: row;
    align-items: center;
    height: 60px;
    @include xs {
      height: 70px;
    }
    &-Logo {
      margin-left: 10px;
      width: 60px;
      height: 100%;
    }
    &-Content {
      width: 100%;
      display: flex;
      flex-direction: column;
      align-items: center;
      color: white;
      p {
        font-size: 14px;
        margin: 0px;
      }
    }
  }

  &-Menu {
    background: $brand_color;
    width: 100vw;
    height: calc(100dvh - 140px);
    bottom: 0px;
    position: absolute;
    overflow: hidden;
    overflow-y: auto;
    -ms-overflow-style: none;
    scrollbar-width: none;
    @include xs {
      // width: auto;
      max-width: 240px;
      height: calc(100dvh - 100px);
      position: relative;
    }
    &_hide {
      display: none;
    }
    &-Item {
      font-size: 16px;
    }
  }

  .LogOut-Icon {
    bottom: 10px;
    @include xs {
      @media (min-height: #{470px}) {
        position: absolute !important;

    }
    }
  }
  .ant-menu-inline-collapsed {
    width: 50px;
  }
  .ant-menu-item-selected {
    background-color: $blue !important;
  }
  .ant-menu-title-content {
    font-weight: 500;
  }
}
