.Picks {
  @include defaultPage {
    &-Header {
      flex-direction: row;
      .Breadcrumb {
        min-width: 150px;
      }
      &-Settings {
        width: 100%;
        margin: 0px 20px;
        display: flex;
        justify-content: flex-end;
      }
    }
    &-Game {
      width: 100%;
      height: fit-content;
      padding: 20px 0px;
      display: flex;
      flex-direction: column;
      align-items: center;
      border-bottom: 1px dashed $silver;
      @include xs {
        flex-direction: row;
        // align-items: center;
        flex-wrap: wrap;
      }
      @include s {
        flex-wrap: nowrap;
      }
      // flex-wrap: wrap;
      &-Header {
        margin: 5px 5px;
        width: fit-content;
        min-width: 110px;
        display: flex;
        justify-content: center;
        &_fixed {
          position: fixed;
          // width: -webkit-fill-available;
          height: 40px;
          top: 67px;
          margin: 0px;
          padding: 0px;
          z-index: 3;
          display: flex;
          justify-content: center;
          align-items: center;
          background: white;
        }
      }
      &-Content {
        width: 100%;
        display: flex;
        flex-direction: row;
        justify-content: space-evenly;
        flex-wrap: wrap;
        @include xl {
          justify-content: flex-start;
        }
        // @include xs {
        //   justify-content: flex-start;
        // }
        &_pt {
          padding-top: 80px;
        }
      }
    }
  }

  &-Settings {
    &-Item {
      margin: 0px;

      &-Group {
        display: flex;
        p {
          margin: 0px 10px;
        }
      }
      .ant-switch-checked {
        background: $green;
        &:hover {
          background: $green !important;
        }
        &:active {
          background: $green !important;
        }
      }
      .anticon-info-circle {
        color: $blue;
        margin: 5px;
        font-size: 18px;
      }
      .ant-form-item-control-input-content {
        width: 100%;
        display: flex;
        flex-direction: row;
        justify-content: space-between;
        align-items: center;
      }
    }
    &-Button {
      margin: 0px;
      margin-top: 15px;
      .ant-form-item-control-input-content {
        width: 100%;
        display: flex;
        flex-direction: row;
        justify-content: flex-end;
        align-items: center;
      }
    }
  }
}
