$brand_color: black;
$button_silver: #d9d9d9;
$alternate-blue: #6687ff;
$blue: #5479ff;
$green: #00a725;
$silver: #d9d9d9;
// $silver: #E1C7C7;

* {
  margin: 0px;
  padding: 0px;
  box-sizing: border-box;
}

body {
  margin: 0px;
  // overflow: hidden;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', Roboto,
    'Helvetica Neue', Arial, 'Noto Sans', sans-serif, 'Apple Color Emoji',
    'Segoe UI Emoji', 'Segoe UI Symbol', 'Noto Color Emoji';
}

.pointer {
  cursor: pointer;
}

.Form {
  max-width: 320px;
  // height: 320px;
  justify-content: center !important;
  align-items: center !important;
  padding: 25px;
  background: white;
  // border: 1px solid #bdbdbd;
  border-radius: 10px;
  // box-shadow: rgba(0, 0, 0, 0.35) 0px 5px 15px;
  .Title {
    min-height: 55px;
    display: flex;
    justify-content: center;
    margin: 0px;
    // .ant-form-item-control-input {
    //   min-height: 10px;
    // }
    .ant-form-item-control-input-content {
      display: flex;
      justify-content: center;
    }
  }
}

.Logo {
  width: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 20px 0px;
}

@mixin defaultPage {
  width: 100%;
  height: 100%;
  display: flex;
  flex-direction: column;
  &-Header {
    padding: 0px 10px;
    margin-top: 10px;
    height: fit-content;
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    // box-shadow: rgba(60, 64, 67, 0.3) 0px 1px 2px 0px, rgba(60, 64, 67, 0.15) 0px 2px 6px 2px;
    // z-index: 10000;
    @include l {
      flex-direction: row;
      justify-content: space-between;
    }
  }
  &-Body {
    width: auto;
    // width: calc(100dvw - 240px);
    height: fit-content;
    padding: 0px 10px;
    // height: 100%
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: row;
    flex-wrap: wrap;
    overflow: hidden;
    overflow-y: auto;
    &_loader {
      height: 100% !important;
    }
  }    
  @content;
}

@mixin scrollbar {
  &::-webkit-scrollbar {
    width: 6px;
    height: 8px;
  }
  &::-webkit-scrollbar-button {
    width: 6px;
    height: 8px;
    // margin: 20px;
  }
  &::-webkit-scrollbar-thumb {
    background: $blue;
    border-radius: 14px;
    border: 1px solid rgb(252, 252, 252);
  }
  &::-webkit-scrollbar-thumb:active {
    // background: rgb(255, 3, 3);
  }
}

.ant-btn-primary {
  background-color: $blue;
  color: white;
  &:hover {
    color: white !important;
    background: $blue !important;
  }
  &:active {
    color: white !important;
    background: $blue !important;
  }
}

.jst-cont-spb {
  justify-content: space-between;
}

.ant-picker-panels {
  display: grid !important;
  @include xs {
    display: inline-flex !important;
  }
}

// .apexcharts-canvas .apexcharts-element-hidden,
// .apexcharts-datalabel.apexcharts-element-hidden,
// .apexcharts-hide .apexcharts-series- {
//   opacity: 0.3 !important;
//   cursor: pointer;
//   width: 20px !important;
//   height: 20px !important;
//   line-height: 24px;

//   svg {
//     width: 20px;
//     height: 17px;
//   }
// }
