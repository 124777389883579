.DateFilters {
  margin: 5px;
  flex: 1;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-wrap: wrap;
  @include xs {
    margin: 5px 0px;
    justify-content: center;
  }
  &-Button {
    width: initial;
    margin: 5px;
    overflow: hidden;
    &_active {
      color: white;
      background: $blue;
      &:hover {
        color: white !important;
        background: $blue !important;
      }
      &:active {
        color: white !important;
        background: $blue !important;
      }
    }
  }
  &-Picker {
    width: 215px;
    margin: 5px;
  }
  &-Select {
    width: 110px;
    margin: 5px;
    @include xs {
      width: 130px;
    }
    &_active {
      .ant-select-selector {
        background: $blue !important;
        border: none !important;
        color: white;
        span {
          color: white;
        }
      }
      .ant-select-selection-item {
        color: white !important;
      }
      .ant-select-arrow {
        color: white;
      }
    }
  }
}

@include xs-less {
  .ant-picker-dropdown {
    left: 15% !important;
  }
}
