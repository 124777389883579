.PickSettings {
  @include defaultPage {
    &-Header {
      justify-content: start;
    }
    &-Body {
      padding: 0px;
      justify-content: center;
      align-items: start;
      &_loader {
        align-items: center;
      }
    }
    &-Card {
      // width: calc((100% - 80px) / 3);
      width: -webkit-fill-available;
      // width: 100%;
      // height: 100%;
      // overflow: scroll;
      max-width: 390px;
      display: flex;
      flex-direction: column;
      align-items: center;
      // padding: 10px;
      padding: 0px 10px;
      margin: 10px 0px;
      @include m {
        // border-left: 1px dashed $silver;
        &:first-child {
          border: none;
        }
      } 
    }
  }
}

.AiPick {
  // width: -webkit-fill-available;
  // width: 100vw;
  min-width: 270px;
  // max-width: 350px;
  height: 100%;
  padding: 0px;
  padding-top: 10px;
  // background: $silver;
  &-Title {
    // width: 100%;
    margin: 0px;
    border-bottom: 1px dashed $silver;
    .p16 {
      font-size: 16px;
    }
    .ant-form-item-control-input-content {
      display: flex;
      flex-direction: column;
      justify-content: center;
      align-items: center;
    }
    &-MInfo {
      width: 100%;
      display: flex;
      justify-content: space-between;
    }
    
  }
  &-Item {
    margin: 0px;
    &-Inp-Group {
      display: flex;
    }
    &-Inp-From-To {
      width: 30px;
      height: 20px;
      margin: 0px 5px;
      padding: 5px;
    }
    &-Inp {
      width: 70px;
      height: 20px;
      margin: 0px 5px;
      padding: 5px;
      text-align: center;
    }
    .min-55 {
      min-width: 75px;
    }
    .min {
      min-width: 55px;
      padding-top: 3px;
      display: flex;
      justify-content: center;
    }
    .ant-form-item-control-input-content {
      width: 100%;
      display: flex;
      flex-direction: row;
      justify-content: space-between;
      align-items: center;
    }
  }
  &-Button {
    margin: 0px;
    margin-top: 15px;
    .ant-form-item-control-input-content {
      width: 100%;
      display: flex;
      flex-direction: row;
      justify-content: flex-end;
      align-items: center;
    }
  }
  p {
    padding: 0px;
    margin: 0px;
  }
}

.ManualPick {
  // width: -webkit-fill-available;
  // width: 100vw;
  min-width: 270px;
  // max-width: 350px;
  height: 100%;
  padding: 0px;
  padding-top: 10px;
  // background: $silver;
  &-Title {
    // width: 100%;
    margin: 0px;
    border-bottom: 1px dashed $silver;
    .p16 {
      font-size: 16px;
    }
    .ant-form-item-control-input-content {
      display: flex;
      flex-direction: column;
      justify-content: center;
      align-items: center;
    }
    &-MInfo {
      width: 100%;
      display: flex;
      justify-content: space-between;
    }
    
  }
  &-Item {
    margin: 0px;
    &-Inp-Group {
      display: flex;
    }
    &-Inp-From-To {
      width: 30px;
      height: 20px;
      margin: 0px 5px;
      padding: 5px;
    }
    &-Inp {
      width: 140px;
      height: 20px;
      margin: 0px 5px;
      padding: 5px;
      text-align: center;
    }
    &-Btn-Group {
      // margin: 0px;
      &-4 {
        width: 200px;
      }
      &-6 {
        width: 250px;
      }
      &-Btn {
        height: 20px;
        margin: 5px;
        border: 1px solid $silver;
        border-radius: 5px;
        line-height: 16px;
        &::before {
          content: none !important;
        }
      }
    }
    .min-55 {
      min-width: 55px;
      padding-top: 3px;
    }
    .min {
      min-width: 65px;
      padding-top: 3px;
    }
    .ant-form-item-control-input-content {
      width: 100%;
      display: flex;
      flex-direction: row;
      justify-content: flex-start;
      // align-items: center;
    }
  }
  &-Button {
    margin: 0px;
    margin-top: 15px;
    .ant-form-item-control-input-content {
      width: 100%;
      display: flex;
      flex-direction: row;
      justify-content: flex-end;
      align-items: center;
    }
  }
  p {
    padding: 0px;
    margin: 0px;
  }
  .BrLine {
    border-bottom: 1px dashed $silver;
  }
}

.PickSettingsCard {
  margin: 10px;
  width: -webkit-fill-available;
  min-width: 360px !important;
  max-width: 440px !important;
  min-height: 0px;
  max-height: 454px;
  box-shadow: rgba(60, 64, 67, 0.3) 0px 1px 2px 0px,
    rgba(60, 64, 67, 0.15) 0px 2px 6px 2px;
  @include xs {
    max-width: 350px;
  }
  &-Market {
    display: flex;
    flex-direction: column;
    &-Title {
      font-weight: 700;
    }
    &-RoundInfo {
      display: flex;
      flex-direction: row;
      justify-content: space-between;
    }
    p {
      margin: 0px;
    }
  }
  &-PickHeader {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
    background: $silver;
    padding: 5px 10px;
    margin: 5px 0px;
    border-radius: 5px;
    font-weight: 700;

    p {
      margin: 0px;
    }
  }
  &-Body {
    width: 100%;
    height: 60px;
    display: flex;
    flex-direction: column;
    align-items: flex-end;
    overflow: hidden;
    overflow-y: auto;
    &_large {
      height: 125px;
    }
    &-AIPick {
      width: 98%;
      height: auto;
      border-radius: 5px;
      background: $silver;
      &-Header {
        // padding: 0px 10px;
        margin: 0px 10px;
        display: flex;
        flex-direction: row;
        justify-content: space-between;
        border-bottom: 1px dashed silver;
        &-Block {
          display: flex;
          flex-direction: row;
        }
        &-Title {
          margin: 5px 0px;
          margin-right: 5px;
          padding: 2px 5px;
          border: 2px solid $green;
          border-radius: 5px;
          background: white;
          line-height: 15px;
        }
      }
      &-Footer {
        margin: 2px 10px;
        display: flex;
        flex-direction: row;
        justify-content: space-between;
        &-Tag {
          margin: 0px 2px;
          padding: 0px 3px;
          background: $green;
          border-color: $green;
          line-height: 15px;
        }
      }
      p {
        margin: 0px;
      }
    }
    &-MPick {
      width: 98%;
      margin-bottom: 5px;
      border-radius: 5px;
      background: $silver;
      &-Content {
        height: 60px;
        display: flex;
        flex-direction: row;
        justify-content: space-around;
        &-Card {
          display: flex;
          flex-direction: column;
          justify-content: center;
          align-items: center;
          &-Tag {
            margin: 0px 2px;
            padding: 0px 3px;
            background: $green;
            border-color: $green;
            line-height: 15px;
          }
          &-Icon {
            font-size: 18px;
            color: red;
          }
        }
      }
    }
    &-Empty {
      width: 100%;
      height: 50px;
      display: flex;
      justify-content: center;
      align-items: center;
      &_large {
        height: 135px;
      }
    }
  }
  .ant-card-head {
    margin: 0px 10px;
    padding: 5px;
    min-height: 30px;
    font-weight: 700;
  }
  .ant-card-body {
    padding: 10px;
  }
}
