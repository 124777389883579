.Table {
  // width: 100% !important;
  // width: 100%;
  background: $silver;
  border-radius: 10px;
  padding: 5px 10px;
  &-Button {
    width: 80px !important;
    height: 30px;
    background: $alternate-blue;
    color: rgb(255, 255, 255);
    // font-size: 25px;
    &:hover {
      color: rgb(235, 235, 235) !important;
      background: $alternate-blue !important;
      // color: black !important;
      border: none;
    }
    &:active {
      color: white !important;
      background: $alternate-blue !important;
      // color: black !important;
      border: none;
    }
    // color: black;
  }
  &-ButtonBlock {
    width: 80px !important;
    height: 30px;
    &_green {
      &:hover {
        border-color: green !important;
      }
      &:active {
        border-color: green !important;
      }
    }
    &_red {
      &:hover {
        border-color: red !important;
      }
      &:active {
        border-color: red !important;
      }
    }
  }
  &_empty {
    table {
      height: 100%;
      border-spacing: 0px 5px;
    }
    &-Content {
      &-Text {
        font-size: 18px;
      }
    }
    .ant-table-tbody {
      height: calc(100vh - 326px);
      @include xs {
        height: calc(100vh - 345px);
      }
    }
  }
  .ant-table-header {
    border-radius: 10px !important;
  }
  .ant-table-row {
    background: white;
    margin: 10px !important;
    border-radius: 10px !important;
    .ant-table-cell {
      border: none;
      border-spacing: 10px !important;
    }
    & td:last-child {
      border-radius: 0px 10px 10px 0px !important;
    }
    & td:first-child {
      border-radius: 10px 0px 0px 10px !important;
      // z-index: 1000px;
    }

    // background: red;
  }
  table {
    border-spacing: 0px 5px;
  }
  .ant-table {
    background: $silver;
  }
  .ant-table-body {
    // height: 100vh;
    height: calc(100dvh - 295px);
    -ms-overflow-style: none;
    scrollbar-width: none;
  }
  .ant-spin-spinning {
    position: initial !important;
  }
  .ant-table-thead {
    // background: white;
    .ant-table-cell {
      width: fit-content;
      font-size: 10px;
      @include xs {
        font-size: 12px;
      }
    }
    & th:last-child {
      border-radius: 0px 10px 10px 0px !important;
    }
    & th:first-child {
      border-radius: 10px 0px 0px 10px !important;
      // background: red;
      // z-index: 1000px;
    }
  }
  .ant-table-cell-scrollbar:not([rowspan]) {
    box-shadow: none;
  }
  .ant-pagination {
    margin: 0px !important;
    margin-top: 5px !important;
  }
  // ant-table-row ant-table-row-level-0
}
