.PicksCard {
  margin: 5px;
  width: inherit;
  min-width: 310px !important;
  // height: 280px;
  box-shadow: rgba(60, 64, 67, 0.3) 0px 1px 2px 0px,
    rgba(60, 64, 67, 0.15) 0px 2px 6px 2px;
  @include xs {
    max-width: 350px;
  }
  &-Market {
    // margin: 5px;
    // padding: 5px;
    // border: 1px dashed rgba(0, 0, 0, 0.592);
    // border-radius: 10px;
    // border-style: dashed;
    &-Title {
      p {
        margin: 0px 5px;
        font-weight: 600;
      }
    }
    &-Content {
      height: 80px;
      display: flex;
      border: 1px dashed rgba(0, 0, 0, 0.592);
      border-radius: 10px;
      margin: 5px;
      padding: 5px;
      position: relative;
      &_loading {
        position: absolute;
        top: 0;
        left: 0;
        z-index: 2;
        -webkit-backdrop-filter: blur(30px) !important;
        backdrop-filter: blur(30px) !important;
        width: 100%;
        height: 100%;
        border-radius: 10px;
        display: flex;
        justify-content: center;
        align-items: center;
        background: repeating-linear-gradient(
            90deg,
            #00000000 10%,
            // rgba(40, 255, 29, 0.123) 25%,
            rgba(55, 96, 219, 0.123) 45%,
            #00000000 90%
          )
          rgba(0, 0, 0, 0);
        background-size: 800% 100%;
        // animation: shine 3s infinite linear;
        animation: shine 3.5s infinite alternate-reverse;
        color: $blue;
        svg {
          margin: 10px;
        }
        &:before {
          // content: '';
          // top: 0;
          // left: 0;
          // width: 50px;
          // height: 50px;
          // background: url('../../../assets/app_logo_b.svg');
          // background-size: cover;
          // margin: 10px;
        }
        &::after {
          // content: 'Processing...';
          // font-weight: 600;
          // padding: 10px;
        }
      }
      &-RoundInfo {
        margin: 0px 5px;
        height: 100%;
        flex: 1;
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;
        &-Tag {
          width: 80px;
          height: 24px;
          margin: 0px;
          display: flex;
          justify-content: center;
          align-items: center;
          background: #880088;
          color: white;
          border: none;
          border-radius: 5px;
          box-shadow: inset 0px 4px 4px rgba(0, 0, 0, 0.25);
        }
      }
      &-PickInfo {
        margin: 0px 5px;
        height: 100%;
        flex: 1;
        display: flex;
        flex-direction: row;
        justify-content: center;
        align-items: center;
        &-Odd {
          height: 100%;
          margin: 10px;
          display: flex;
          flex-direction: column;
          justify-content: space-around;
          &-Tag {
            width: 80px;
            height: 24px;
            display: flex;
            justify-content: center;
            align-items: center;
            // margin: 5px 0px;
            border: none;
            border-radius: 5px;
            background: $green;
            color: white;
            box-shadow: inset 0px 4px 4px rgba(0, 0, 0, 0.25);
          }
        }
      }
      p {
        margin: 0px;
        padding: 0px;
      }
    }
  }
  .ant-card-head {
    margin: 0px 10px;
    padding: 5px;
    min-height: 30px;
    font-weight: 700;
  }
  .ant-card-body {
    padding: 10px;
  }
}

@keyframes shine {
  0% {
    background-position: right;
  }
}
