.GamesSwitcher {
  margin: 5px 0px;
  flex: 0.3;
  // width: calc(100% - 370px);
  min-width: 375px;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-wrap: wrap;
  &-Button {
    width: initial;
    margin: 5px;
    overflow: hidden;
    &_active {
      color: white;
      background: $blue;
      &:hover {
        color: white !important;
        background: $blue !important;
      }
      &:active {
        color: white !important;
        background: $blue !important;
      }
    }
  }
  &-Picker {
    width: 240px;
    margin: 5px;
  }
}