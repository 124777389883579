.Dashboard {
  @include defaultPage {
    &-Header {
      .DateFilters {
        justify-content: flex-start;
      }
    }
    &-Chart {
      width: 100%;
      height: min-content;
      min-height: 266px;
      max-height: 265px;
      min-width: 250px;
      max-width: 575px;
      margin: 10px;
      box-shadow: rgba(60, 64, 67, 0.3) 1px 1px 12px 1px;
      &-Header {
        display: flex;
        flex-direction: column;
        height: 50px;
        padding: 5px 15px;
        h4 {
          margin: 0px;
        }
        &_left {
          width: 100%;
        }
        &_center {
          width: 100%;
          display: flex;
          justify-content: center;
        }
      }
    }
  }
}
