.AuthMain {
  width: 100%;
  height: 100vh;
  display: flex;
  justify-content: center;
  // align-items: center;  
  .Body {
    width: 100%;
    background: #F1F1F1;
  }
  .Content {
    display: flex;
    flex-direction: column;
    // justify-content: space-evenly;
    align-items: center;
    background: #F1F1F1;
    // background: red;
  }
  .LanguageSwitcher {
    width: 100%;
    display: flex;
    justify-content: flex-end;
  }
}