.Card {
  margin: 10px;
  width: 100%;
  min-width: 300px;
  box-shadow: rgba(60, 64, 67, 0.3) 0px 1px 2px 0px,
    rgba(60, 64, 67, 0.15) 0px 2px 6px 2px;
  @include xs {
    max-width: 500px;
  }
  &-Header {
    display: flex;
    margin: 0px 5px;
    flex-direction: row;
    justify-content: space-around;
    &-Tag {
      // width: 100%;
      width: 120px;
      height: 25px;
      display: flex;
      justify-content: center;
      align-items: center;
      text-overflow: ellipsis;
    }
  }
  &-Market {
    margin: 5px;
    padding: 5px;
    border: 1px dashed rgba(0, 0, 0, 0.592);
    border-radius: 10px;
    // border-style: dashed;
    &-Price {
      width: 100%;
      display: flex;
      flex-direction: row;
      justify-content: space-evenly;
      margin: 0px;
      margin: 5px 0px;
      &-Tag {
        width: 100%;
        // width: -webkit-fill-available;
        height: 25px;
        display: flex;
        justify-content: center;
        align-items: center;
        border-radius: 5px;
        background: #E1C7C7 /* $button_silver*/;
        border-color: #E1C7C7;
        font-weight: 600;
        text-overflow: ellipsis;
        margin: 0px 2px;
      }
    }
  }
  .ant-card-head {
    margin: 0px 15px;
    padding: 5px;
    min-height: 30px;
    font-weight: 700;
  }
  .ant-card-body {
    padding: 10px;
  }
}
