.Space-8 {
  overflow-y: auto;
  &_pt {
    padding-top: 80px;
  }
  @include defaultPage {
    position: relative;
    // height: auto;
    &-Header {
      &-DateFilters {
        width: 100%;
        display: flex;
        justify-content: center;
        @include xl {
          position: absolute;
        }
      }
      &_fixed {
        position: fixed;
        // width: -webkit-fill-available;
        width: 100%;
        height: 40px;
        top: 50px;
        left: 0px;
        margin: 0px;
        padding: 0px;
        z-index: 30;
        display: flex;
        justify-content: center;
        align-items: center;
        background: white;
      }
    }
    &-Body {
      overflow: unset;
      overflow-y: unset;
    }
    &-MarketInfo {
      margin: 5px 15px;
      display: flex;
      flex-direction: column;
      justify-content: center;
      align-items: 'start';
      @include xs {
        align-items: center;
      }
      &_center {
        display: flex;
        flex-direction: column;
        align-items: center !important;
      }

      &_none {
        display: none !important;
      }

      &-Prices {
        @include xs {
          width: 100%;
        }
      }
      &-Button {
        width: initial;
        margin: 5px;
        overflow: hidden;
        &_active {
          color: white;
          background: $blue;
          &:hover {
            color: white !important;
            background: $blue !important;
          }
          &:active {
            color: white !important;
            background: $blue !important;
          }
        }
      }
    }
    &-Chart {
      position: relative;
      width: 100%;
      // height: max-content;
      // max-height: 275px;
      min-width: 250px;
      // max-width: 575px;
      margin: 10px;
      border-radius: 5px;
      box-shadow: rgba(60, 64, 67, 0.3) 1px 1px 12px 1px;

      .Space-8-Chart-Scrollable {
        overflow: hidden;
        overflow-x: scroll;
        // -ms-overflow-style: none;
        // scrollbar-width: none;
        @include scrollbar;
        .Space-8-Chart-YAxis {
          width: 40px;
          height: calc(100% - 40px);
          top: 0px;
          z-index: 10;
          display: flex;
          flex-direction: column-reverse;
          justify-content: flex-start;
          align-items: center;
          position: absolute;
          background: white;
          // gap: 15px;
          span {
            font-size: 14px;
            // line-height: 21px;
            padding: 3.5px;
          }
        }
      }
      &-Header {
        display: flex;
        flex-direction: column;
        justify-content: end;
        // align-items: center;
        padding: 5px 15px;
        h4,
        h6,
        p {
          margin: 0px;
        }
        &-Title {
          display: flex;
        }
        &-Button {
          width: initial;
          margin: 5px;
          height: 22px;
          line-height: 0px;
          overflow: hidden;
          &_active {
            color: white;
            background: $blue;
            &:hover {
              color: white !important;
              background: $blue !important;
            }
            &:active {
              color: white !important;
              background: $blue !important;
            }
          }
        }
        &_left {
          width: 100%;
        }
        &_right {
          width: 100%;
          display: flex;
          justify-content: flex-end;
        }
        &_center {
          width: 100%;
          min-height: 20px;
          display: flex;
          justify-content: center;
          align-items: center;
        }
        &_between {
          width: 100%;
          min-height: 20px;
          display: flex;
          justify-content: space-between;
          align-items: center;
        }
        &-Legend {
          min-width: 30px;
          // padding: 1px 5px;
          margin: 5px;
          padding: 0px 5px;
          height: 20px;
          border-radius: 5px;
          display: flex;
          justify-content: center;
          align-items: center;
          color: white;
          font-size: 12px;
          font-weight: 500;
        }
      }
      &-Group {
        width: 100%;
        display: flex;
        flex-direction: row;
        flex-wrap: wrap;
        justify-content: center;
        .Space-8-Chart {
          flex: 1;
        }
      }
      &-Custom {
        position: relative;
        width: 100%;
        min-height: 200px;
        display: flex;
        flex-direction: row;
        flex-wrap: nowrap;
        // overflow: hidden;
        overflow-y: auto;
        @include scrollbar;

        &-Line {
          position: absolute;
          top: 25px;
          left: 0px;
          &-Item {
            width: 100%;
            height: 21px;
            box-sizing: border-box;
            border-bottom: 1px solid silver;
            // border-top: 1px solid white;
          }
        }
        &-Col {
          z-index: 5;
          min-width: 32px;
          margin: 5px;
          display: flex;
          flex-direction: column;
          align-items: center;
          &-Item {
            width: 15px;
            height: 15px;
            border-radius: 50%;
            margin: 3px 0px;
            display: flex;
            justify-content: center;
            align-items: center;
          }
          p {
            min-height: 20px;
            margin: 0px;
            font-size: 12px;
          }
        }
      }
    }
    &-Analysis {
      width: 100%;
      margin: 10px;
      display: flex;
      flex-direction: column;
      flex-wrap: wrap;
    }
    &-PatternMain {
      width: 100%;
      // flex: 1 1;
      min-width: 310px;
      height: 585px;
      // height: 50px;
      margin-bottom: 20px;
      // padding: 10px;
      border-radius: 5px;
      display: flex;
      flex-direction: row;
      align-items: center;
      overflow: hidden;
      // flex-wrap: wrap;
      box-shadow: rgba(60, 64, 67, 0.3) 1px 1px 12px 1px;
      &-Col {
        flex-direction: column;
        .Space-8-PatternMain-Pattern {
          // height: calc(50% - 40px);
        }
      }
      &-Pattern {
        display: flex;
        flex-direction: column;
        // flex: 1;
        width: 100%;
        min-width: 300px;
        max-width: 925px;
        // height: calc(100% - 20px);
        max-height: calc(100% - 230px);
        margin: 0px 10px;
        // background: red;
        &-MHN {
          // min-height: 0px !important;
          min-height: 200px;
        }
        &-MW {
          max-width: unset;
        }
        @include xs {
          // min-height: 280px;
        }
        &-Header {
          display: flex;
          flex-direction: column;
          justify-content: end;
          // align-items: center;
          // height: 50px;
          padding: 5px 15px;
          h4,
          h6,
          p {
            margin: 0px;
          }
          &_left {
            width: 100%;
            min-height: 25px;
          }
          &_right {
            width: 100%;
            display: flex;
            justify-content: flex-end;
          }
          &_center {
            width: 100%;
            display: flex;
            justify-content: center;
            align-items: center;
          }
          &_between {
            width: 100%;
            min-height: 20px;
            display: flex;
            justify-content: space-between;
            align-items: center;
          }
        }
        &-Body {
          height: 100%;
          display: flex;
          flex-direction: column;
          // justify-content: center;
          align-items: center;
          margin: 0px 15px;
          &-Button {
            width: initial;
            margin: 5px;
            height: 22px;
            line-height: 0px;
            overflow: hidden;
            &_active {
              color: white;
              background: $blue;
              &:hover {
                color: white !important;
                background: $blue !important;
              }
              &:active {
                color: white !important;
                background: $blue !important;
              }
            }
          }
          &-Balls {
            max-width: 100%;
            min-height: 70px;
            display: flex;
            flex-direction: row;
            // justify-content: center;
            align-items: center;
            overflow: hidden;
            overflow-x: auto;
            // border: 1.5px solid black;
            border-radius: 5px;
            margin: 10px;
            -ms-overflow-style: none;
            scrollbar-width: none;
            .Space-8-Ball {
              width: 16px;
              height: 16px;
              font-size: 6px;
              @include xs {
                width: 26px;
                height: 26px;
                font-size: 11px;
              }
            }
            &-Box {
              display: flex;
              flex-direction: column;
              align-items: center;
              margin: 2px;
              border-radius: 50vw 50vw 10vw 10vw;
              &-Hued {
                width: 55.5px;
                background: #f2f2f2;
              }
              p {
                font-size: 11px;
              }
            }
            &-Scrolling {
              display: flex;
              flex-direction: row;
              &:has(> *:nth-child(5)) {
                .Space-8-Ball {
                  width: 40px;
                  height: 40px;
                  font-size: 12px;
                  // margin: 5px;
                  @include xs {
                    width: 40px;
                    height: 40px;
                    font-size: 12px;
                  }
                }
              }
              &:has(> *:nth-child(10)),
              :has(> *:nth-child(15)) {
                .Space-8-Ball {
                  width: 25px;
                  height: 25px;
                  font-size: 10px;
                  // margin: 2px;
                  @include xs {
                    width: 40px;
                    height: 40px;
                    font-size: 12px;
                    // margin: 3px;
                  }
                }
              }
              // &:has(> *:nth-child(15)) {
              //   .Space-8-Ball {
              //     width: 18px;
              //     height: 18px;
              //     font-size: 7px;
              //     // margin: 1px;
              //     @include xs {
              //       width: 34px;
              //       height: 34px;
              //       font-size: 11px;
              //       // margin: 2px;
              //     }
              //   }
              // }
            }
          }
        }
        .PatternTable {
          // width: 100%;
          height: 100%;
          margin: 5px;
          display: flex;
          flex-direction: column;
          overflow: hidden;

          &-Bordered {
            border: 1.5px solid black;
            padding: 10px;
            margin: 0px 15px;
            border-radius: 5px;
          }
          &-Empty {
            min-height: 340px;
            .PatternTable-Body {
              display: flex;
              justify-content: center;
              align-items: center;
            }
          }
          &-Header {
            height: 30px;
            display: flex;
            // justify-content: space-between;
            align-items: center;
            border: 2px black solid;
            border-radius: 5px;
            font-size: 9px;
            @include s {
              font-size: 12px;
            }
            @include xl {
              font-size: 14px;
            }
            &-Col {
              display: flex;
              justify-content: center;
              padding: 10px;
              text-wrap: nowrap;

              span {
                font-weight: 600;
              }
            }
            .empty {
              width: 5%;
            }
            .date {
              width: 17.5%;
            }
            .pattern {
              width: 60%;
            }
            .result {
              width: 17.5%;
            }
          }

          &-Body {
            width: 100%;
            height: 100%;
            flex-direction: column;
            overflow: auto;
            -ms-overflow-style: none;
            scrollbar-width: none;
            &-MaxH {
              // max-height: 240px;
            }
            &-Row {
              // width: 100%;
              display: flex;
              // justify-content: space-between;
              // box-sizing: border-box;
              border: 1.5px black solid;
              border-radius: 5px;
              margin: 10px 0px;
              // position: relative;
              &-Large {
                height: 45px;
              }

              &-Col {
                display: flex;
                justify-content: center;
                align-items: center;
                text-wrap: nowrap;
                overflow: hidden;
                text-overflow: ellipsis;
                overflow-x: auto;
                -ms-overflow-style: none;
                scrollbar-width: none;
                font-size: 10px;
                @include s {
                  font-size: 12px;
                }
                @include xl {
                  font-size: 14px;
                }
              }
              .empty {
                width: 5%;
              }
              .date {
                width: 17.5%;
              }
              .ballBoxMain {
                width: 60%;
                display: flex;
                justify-content: center;
                overflow: unset;
                overflow-x: unset;
              }
              .ballBox {
                max-width: 100%;
                display: flex;
                flex-direction: row;
                justify-content: unset;
                overflow: hidden;
                overflow-x: auto;
              }
              .ballBoxScroll {
                display: flex;
                flex-direction: row;
                overflow: unset;
              }
              .result {
                width: 17.5%;
              }
            }
          }
        }
      }
      &-PosAnalysis {
        width: 100%;
        display: flex;
        flex-direction: row;
        justify-content: center;
        flex: 1 1;
        flex-wrap: wrap;
        .PatternTable {
          width: 100%;
        }
      }
      &-Table {
        display: flex;
        flex: 1 1;
      }
      &-HAuto {
        height: auto;
        min-height: 230px;
      }
    }
  }
  &-Ball {
    width: 25px;
    height: 25px;
    margin: 1px;
    border-radius: 50%;
    display: flex;
    justify-content: center;
    align-items: center;
    color: white;
    cursor: pointer;
    // box-shadow: inset 0px 4px 4px rgba(0, 0, 0, 0.25);

    font-size: 12px;
    font-weight: 500;
    @include xxl {
      width: 29px;
      height: 29px;
      // margin: 2px;
      border: 1px solid;
    }
    &-1 {
      // background: red;
      background: radial-gradient(
        circle at 10% 0%,
        rgb(255, 66, 66),
        hsla(0, 100%, 36%, 0.959)
      );
    }
    &-2 {
      background: radial-gradient(circle at 30% 30%, #ffff00, #774400);
    }
    &-3 {
      // background: #02ac02;
      background: radial-gradient(circle at 30% 30%, #1eff00, #774400);
    }
    &-4 {
      // background: #0000ff;
      background: radial-gradient(circle at 30% 30%, #0554ff, #412222);
    }
    &-5 {
      // background: rgb(168, 6, 168);
      background: radial-gradient(circle at 30% 30%, #e205ff, #412222);
    }
    &-6 {
      background: black;
      box-shadow: inset 0px 4px 4px rgba(255, 255, 255, 0.64);
    }
  }
  .blue {
    background: radial-gradient(circle at 30% 30%, #37a1f1, #3c6dff);
  }
  .green {
    background: radial-gradient(circle at 30% 30%, #00e396, rgb(61, 175, 32));
  }
  .red {
    background: radial-gradient(
      circle at 10% 0%,
      rgb(255, 66, 66),
      hsla(0, 100%, 36%, 0.959)
    );
  }
  .yellow {
    background: radial-gradient(circle at 30% 30%, #ffff00, #774400);
  }
  .purpure {
    background: radial-gradient(circle at 30% 30%, #e205ff, #412222);
  }
  .black {
    background: black;
    box-shadow: inset 0px 4px 4px rgba(255, 255, 255, 0.64);
  }
  .silver {
    background: #888889;
    box-shadow: inset 0px 4px 4px rgba(255, 255, 255, 0.64);
  }
}
